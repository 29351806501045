var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-0", attrs: { "no-body": "" } },
        [
          _c("div", { staticClass: "m-2" }, [
            _c("form", { staticClass: "row justify-content-between" }, [
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c("v-select", {
                    attrs: {
                      label: "title",
                      required: "",
                      options: _vm.perPageOptions,
                      clearable: false,
                    },
                    on: { input: _vm.setPerPageSelected },
                    model: {
                      value: _vm.perPage,
                      callback: function ($$v) {
                        _vm.perPage = $$v
                      },
                      expression: "perPage",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "col-md-4" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filter,
                      expression: "filter",
                    },
                  ],
                  staticClass: "d-inline-block form-control",
                  attrs: { type: "search", placeholder: "Pesquisar..." },
                  domProps: { value: _vm.filter },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.filter = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "sticky-header": true,
              "no-border-collapse": true,
              items: _vm.permissions,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              "empty-text": "Nenhum registro encontrado",
              "empty-filtered-text": "Nenhum registro encontrado",
              filter: _vm.filter,
              "filter-included-fields": _vm.filterOn,
              striped: "",
              hover: "",
            },
            on: { filtered: _vm.onFiltered },
            scopedSlots: _vm._u([
              {
                key: "cell(created_at)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("datePT")(data.item.created_at)) + " "
                    ),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "form-row align-items-center justify-content-center",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col-md-6 text-center" },
                          [
                            _c("feather-icon", {
                              staticClass: "cursor-pointer cursor text-danger",
                              attrs: {
                                id:
                                  "customer-" + data.item.uuid + "-delete-icon",
                                icon: "TrashIcon",
                                size: "16",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.confirmDelete(data.item.uuid)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6 text-center" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  id:
                                    "customer-" + data.item.uuid + "-edit-icon",
                                  to: {
                                    name: "config-permissions-edit",
                                    params: { uuid: data.item.uuid },
                                  },
                                },
                              },
                              [
                                _c("feather-icon", {
                                  staticClass: "cursor-pointer cursor",
                                  attrs: { icon: "EditIcon", size: "16" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("div", { staticClass: "mx-2 mb-2 mt-2 paginate-area" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center justify-content-sm-start col-md-6",
                },
                [
                  _c("span", {}, [
                    _vm._v(
                      " Foram encontrados " +
                        _vm._s(_vm.totalRows) +
                        " resultados "
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "col-md-6 d-flex align-items-center justify-content-center justify-content-sm-end",
                },
                [
                  _c("b-pagination", {
                    staticClass: "my-1",
                    attrs: {
                      "total-rows": _vm.totalRows,
                      "per-page": _vm.perPage,
                      limit: 10,
                      "first-number": "",
                      "last-number": "",
                      "prev-class": "prev-item",
                      "next-class": "next-item",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.updatePage()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prev-text",
                        fn: function () {
                          return [
                            _c("feather-icon", {
                              attrs: { icon: "ChevronLeftIcon", size: "18" },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "next-text",
                        fn: function () {
                          return [
                            _c("feather-icon", {
                              attrs: { icon: "ChevronRightIcon", size: "18" },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.currentPage,
                      callback: function ($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }