<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <form class="row justify-content-between">
          <div class="col-md-2">
            <v-select
              label="title"
              required
              v-model="perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </div>
          <div class="col-md-4">
            <input
              type="search"
              class="d-inline-block form-control"
              placeholder="Pesquisar..."
              v-model="filter"
            />
          </div>
        </form>
      </div>

      <b-table
        :sticky-header="true"
        :no-border-collapse="true"
        class="position-relative"
        :items="permissions"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        empty-filtered-text="Nenhum registro encontrado"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        striped
        hover
      >
        <template #cell(created_at)="data">
          {{ data.item.created_at | datePT }}
        </template>
        <template #cell(actions)="data">
          <div class="form-row align-items-center justify-content-center">
            <div class="col-md-6 text-center">
              <feather-icon
                :id="`customer-${data.item.uuid}-delete-icon`"
                icon="TrashIcon"
                class="cursor-pointer cursor text-danger"
                size="16"
                @click="confirmDelete(data.item.uuid)"
              />
            </div>
            <div class="col-md-6 text-center">
              <router-link
                :id="`customer-${data.item.uuid}-edit-icon`"
                :to="{
                  name: 'config-permissions-edit',
                  params: { uuid: data.item.uuid },
                }"
              >
                <feather-icon
                  icon="EditIcon"
                  class="cursor-pointer cursor"
                  size="16"
                />
              </router-link>
            </div>
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2 mt-2 paginate-area">
        <div class="row">
          <div
            class="d-flex align-items-center justify-content-center justify-content-sm-start col-md-6"
          >
            <span class=""> Foram encontrados {{ totalRows }} resultados </span>
          </div>
          <div
            class="col-md-6 d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              :limit="10"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="my-1"
              @input="updatePage()"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BBadge,
  BPagination,
  BSpinner,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BPagination,
    BSpinner,
    BCardText,
    vSelect,
  },
  data() {
    return {
      permissions: [],
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 50,
      totalRows: 0,
      rowsTable: 0,
      perPageOptions: [
        {
          title: "50",
          value: 50,
        },
        {
          title: "100",
          value: 100,
        },
      ],
      tableColumns: [
        {
          key: "name",
          label: "Nome",
          sortable: false,
        },
        {
          key: "created_at",
          label: "Cadastrado",
          sortable: true,
          class: "text-center",
          thStyle: "width: 190px",
        },
        {
          key: "actions",
          label: "",
          thClass: "text-center td-actions",
        },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Tem certeza?",
        text: "Só será possível deletar o usuário caso não tenha nenhum cadastro vinculado.",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Permission/delete", { uuid: uuid }).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "O usuário foi deletado com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
    updatePage() {
      this.getData();
    },
    async getData() {
      this.$store
        .dispatch("Permission/all", {
          currentPage: this.currentPage,
          perPage: this.perPage,
        })
        .then((res) => {
          if (res.data.length) {
            this.permissions = res.data;
            this.dataItems = res.data;
            this.rowsTable = res.data.length;
            this.totalRows = res.meta.total;
            this.currentPage = res.meta.current_page;
          }
        })
        .finally(() => {
          this.btnSearch = false;
        });
    },
  },
};
</script>
